#whatsapp {
    position: fixed;
    bottom: 20px;
    right: 19px;
    z-index: 9999;
    display: flex;
    align-items: center;
}

.qlwapp-toggle {
    display: flex;
    align-items: center;
    text-decoration: none;
   
}

.qlwapp-text {
    margin-right: 10px; /* Spazio tra il testo e l'icona */
    font-size: 16px; /* Dimensione del testo */
    color: black; /* Colore del testo */
    background-color: white; /* Sfondo bianco per il testo */
    padding: 5px 10px; /* Spaziatura interna per il testo */
    border-radius: 25px; /* Angoli arrotondati */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Ombra */
    margin-right: 7px;
    transition: all 0.15s ease-in-out;

}

#whatsapp i.qlwapp-whatsapp-icon {
    display: inline-block;
    width: 54px;
    height: 54px;
    background-image: url('https://cdn-icons-png.flaticon.com/512/174/174879.png');
    background-color: white; 
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 100%; /* Forma rotonda */
    transition: all 0.15s ease-in-out;
}

/* #whatsapp i.qlwapp-whatsapp-icon:hover {
    transform: scale(1.2);
} */

