.ring {
  width: 200px;
  height: 200px;
  border: 10px solid #d4af37;
  border-radius: 50%;
  position: relative;
}

.ring::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  width: 180px;
  height: 180px;
  border-radius: 50%;
}

/* Mobile responsiveness */
@media (max-width: 640px) {
  .ring {
    width: 100px;
    height: 100px;
    border: 5px solid #d4af37;
  }

  .ring::before {
    width: 90px;
    height: 90px;
  }
}
