.buttonHero{
    margin-right: 10px; /* Spazio tra il testo e l'icona */
    font-size: 16px; /* Dimensione del testo */
    color: black; /* Colore del testo */
    background-color: white; /* Sfondo bianco per il testo */
    padding: 5px 10px; /* Spaziatura interna per il testo */
    border-radius: 25px; /* Angoli arrotondati */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Ombra */
    margin-right: 7px;

}

/* PulsanteHero.css */

/* Stile per le frecce di navigazione */
/* 
.next-arrow {
  position: absolute;
  top: 55%;
  transform: translateY(-40%);
  width: 3rem;
  height: 3rem;
  color: #ffffff; 
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}








.next-arrow {
  right: 1rem; 
} */


.next-arrow,
.prev-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  color: white; /* Colore delle frecce */
}

.next-arrow {
  right: 10px; /* Distanza dalla destra */
}

.prev-arrow {
  left: 10px; /* Distanza dalla sinistra */
}

