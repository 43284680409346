.nav-link {
    position: relative;
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;

  }
  
  .nav-link::before {
    content: '';
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: 0;
    left: 1;
    background-color: white;
    transition: width 0.3s ease;
  }
  
  .nav-link:hover::before {
    width: 87%;
  }
  